<template>
  <div class="wrapper">
    <div class="image-container">
      <img src="@/assets/santas-sack-1.png" alt="Santa's Sack">
    </div>
    <div class="overlay-text">Santa's sack, it's joy we won't lack</div>
  </div>
</template>

<script>
export default {
  name: 'SantasSackMain',
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Mountains+of+Christmas:wght@700&display=swap');

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

img {
  max-width: 100%;
  height: auto;
  border-radius: 15px;
}

.overlay-text {
  margin-top: 15px;
  font-family: 'Mountains of Christmas', cursive;
  font-size: 2em;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-align: center;
}
</style>