<template>
  <SantasSackMain />
</template>

<script>
import SantasSackMain from './components/SantasSackMain.vue';

export default {
  name: 'SantasSackApp',
  components: {
    SantasSackMain
  },
  mounted() {
    document.title = 'Santa\'s Sack App';
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>